import { useMemo } from "react";
import { windupFromString } from "../Windup";
import useWindup from "./useWindup";
import { renderStringWindup } from "./renderWindup";
export default function useWindupString(text, options = {}) {
    const windupInit = useMemo(() => {
        return windupFromString(text, options);
    }, [text]);
    const { windup, skip, rewind, isFinished } = useWindup(windupInit, options);
    return [renderStringWindup(windup), { skip, rewind, isFinished }];
}
